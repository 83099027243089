<template>
  <div class="product">
    <div class="top common">
      <img src="../assets/img/产品.jpg" alt="" />
      <img
        src="../assets/img/arrow.gif"
        class="arrow"
        @click="gotoself('.content')"
        alt=""
      />
      <div class="topleft">
        <Nav />
      </div>
      <!-- <div class="toptitle">
        <div class="topcn">巧传情谊，无需言喻</div>
        <div class="topen">Cleverly convey</div>
        <div class="topen">the friendship, needless to say</div>
      </div> -->
    </div>
    <div class="black"></div>
    <!-- 产品轮播图 -->
    <div class="common content">
      <div
        v-if="goodsList.length > 0"
        class="contain"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <swiper
          ref="mySwiper"
          class="swiper"
          @click.native="handleSwiperDOMClick"
          :options="swiperOption"
        >
          <swiper-slide v-for="item in goodsList" :key="item.id">
            <div class="containitem">
              <img
                :src="item.img"
                alt=""
                :data-id="item.id"
                :class="item.id == 4 ? 'shenglai-img' : ''"
              />
              <p>{{ item.name }}</p>
              <div class="common explore" :data-id="item.id">
                Explore
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 产品轮播图 - end -->
    <div class="black"></div>
    <div class="common flexbox">
      <img
        src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品2级页面/product1.jpg"
        alt=""
      />
      <img
        src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品2级页面/product2.jpg"
        alt=""
      />
      <img
        src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品2级页面/product3.jpg"
        alt=""
      />
    </div>
    <div class="black"></div>
    <Footer />
  </div>
</template>

<script>
// import Title from "../components/Title.vue";
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";

export default {
  components: {
    // Title,
    Footer,
    Nav
  },
  data() {
    return {
      current: 0,
      tabs: [
        { id: 1, name: "7P普洱生茶" },
        { id: 2, name: "7T普洱熟茶" },
        { id: 3, name: "7A古树白茶" },
        { id: 4, name: "7C古树红茶" }
      ],
      goodsList: [
        {
          id: 0,
          name: "7P普洱生茶",
          img: require("../assets/img/shengcha.png")
        },
        {
          id: 1,
          name: "7T普洱熟茶",
          img: require("../assets/img/shucha.png")
        },
        {
          id: 2,
          name: "7A古树白茶",
          img: require("../assets/img/baicha.png")
        },
        {
          id: 3,
          name: "7C古树红茶",
          img: require("../assets/img/hongcha.png")
        },
        {
          id: 4,
          name: "生来不凡",
          img: require("../assets/img/shenglai.png")
        }
      ],
      // 产品轮播图设置
      swiperOption: {
        loopedSlides: 4,
        slidesPerView: 4, // 显示的个数
        spaceBetween: 10, //slide之间的间距（减去原有样式产生的差）
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          waitForTransition: true
        },
        preventLinksPropagation: false // 阻止点击事件冒泡
      }
    };
  },
  computed: {
    // 设置 swiper
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  mounted() {
    this.current = this.$route.query.tab * 1 || 0;
  },
  methods: {
    // loop为true时，dom绑定点击事件无效，所以在此处理, 添加data-id为触发点击事件的条件
    handleSwiperDOMClick(event) {
      const index = event.target.dataset.id;
      if (!index) return;
      // 跳转到对应详情
      this.todetail(index);
    },
    // 鼠标移入时停止播放
    onMouseEnter() {
      this.swiper.autoplay.stop();
    },
    // 鼠标移出时开始播放
    onMouseLeave() {
      this.swiper.autoplay.start();
    },
    tabTap(index) {
      this.current = index;
      if (index == 0) {
        this.proList = [
          {
            id: 1,
            url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/图1.jpg",
            des1: "生茶",
            des2: "精香甜适",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7P生茶
              </div>
              <div style="color: #666666;">
                 4盒*32g小沱
              </div>
            </div>`
          },
          {
            id: 1,
            url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/图1.jpg",
            des1: "生茶",
            des2: "精香甜适",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7P生茶
              </div>
              <div style="color: #666666;">
                  128g饼茶
              </div>
            </div>`
          }
        ];
      }
      if (index == 1) {
        this.proList = [
          {
            id: 1,
            url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/图2.jpg",
            des1: "熟茶",
            des2: "栗红透亮，甜醇顺滑",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7T熟茶
              </div>
              <div style="color: #666666;">
                 4盒*32g小沱
              </div>
            </div>`
          },
          {
            id: 2,
            url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/图2.jpg",
            des1: "熟茶",
            des2: "栗红透亮，甜醇顺滑",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7T熟茶
              </div>
              <div style="color: #666666;">
                  128g饼茶
              </div>
            </div>`
          }
        ];
      }
      if (index == 2) {
        this.proList = [
          {
            id: 1,
            url:
              "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/_24A7095.jpg",
            des1: "白茶",
            des2: "明亮甘甜，香气持久",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7A白茶
              </div>
              <div style="color: #666666;">
                  8盒*8g小饼
              </div>
            </div>`
          },
          {
            id: 2,
            url:
              "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/_24A7095.jpg",
            des1: "白茶",
            des2: "明亮甘甜，香气持久",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7A白茶
              </div>
              <div style="color: #666666;">
                  16袋*8g小饼
              </div>
            </div></div>`
          },
          {
            id: 3,
            url:
              "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/_24A7095.jpg",
            des1: "白茶",
            des2: "明亮甘甜，香气持久",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7A白茶
              </div>
              <div style="color: #666666;">
                  128g饼茶
              </div>
            </div>`
          }
        ];
      }
      if (index == 3) {
        this.proList = [
          {
            id: 1,
            url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/图4.jpg",
            des1: "红茶",
            des2: "一芽一叶，花香馥郁",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7C红茶
              </div>
              <div style="color: #666666;">
                  8盒*8g小饼
              </div>
            </div>`
          },
          {
            id: 2,
            url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/图4.jpg",
            des1: "红茶",
            des2: "一芽一叶，花香馥郁",
            html: `<div style="display:flex;justify-content: space-between;">
              <div style="color: #424242;">
                7C红茶
              </div>
              <div style="color: #666666;">
                  16袋*8g小饼
              </div>
            </div>`
          }
        ];
      }
    },
    // 跳转到对应详情
    todetail(index) {
      this.$router.push(`/productDetail?index=${index}`);
    },
    gotoself(key) {
      this.$el.querySelector(key).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start" // 上边框与视窗顶部平齐
      });
    }
  }
};
</script>

<style lang="less" scoped>
.common {
  margin: 0 auto;
}
.top {
  height: 1080px;
  position: relative;
  // margin-bottom: 38px;
  img {
    width: 100%;
    height: 100%;
  }
  .arrow {
    width: 50px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    cursor: pointer;
  }
  .topleft {
    position: absolute;
    height: 100%;
    width: 415px;
    background: rgba(0, 0, 0, 0.1) no-repeat center;
    left: 0;
    top: 0;
    // background-image: url("https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/logo.png");
    // background-size: 135px 190px;
  }
  .toptitle {
    position: absolute;
    top: 470px;
    // width: 100%;
    height: 310px;
    color: #ffffff;
    left: 530px;
    .topcn {
      font-size: 37px;
      // margin-left: 415px;
      // margin-top: 54px;
      // text-align: center;
      font-family: "source serif Bold";
    }
    .topen {
      font-size: 37px;
      // margin-left: 415px;
      // text-align: center;
      font-family: "source serif light";
    }
  }
}
.content {
  background: #000;
  height: 1080px;
  .contain {
    margin-left: 115px;
    margin-right: 115px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    .containitem {
      width: 410px;
      height: 100%;
      background: #1a1a1a;
      color: #fff;
      font-size: 42px;
      transition: 0.5s;
      text-align: center;
      img {
        width: 100%;
        height: 350px; // 添加了高度限制，是图片切的不统一
        margin-top: 200px;
        filter: brightness(70%);
      }
      // 生来图片尺寸太小，单独给了样式
      .shenglai-img {
        width: 50%;
        height: 200px;
        margin-top: 270px;
        margin-bottom: 80px;
      }
      // img:hover {
      //   cursor: pointer;
      //   filter: brightness(120%);
      // }
      &:hover > img {
        cursor: pointer;
        filter: brightness(120%);
      }
      p {
        text-align: center;
        margin-top: 200px;
        margin-bottom: 50px;
      }

      .explore {
        width: 142px;
        height: 32px;
        background: #ecb94b;
        font-size: 19px;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        opacity: 0.7;
        transition: 0.7s;
      }
      // .explore:hover {
      //   opacity: 1;
      // }
      &:hover > .explore {
        opacity: 1;
      }
    }
    .containitem:hover {
      font-size: 46px;
    }
  }
}
.flexbox {
  display: flex;
  // margin-bottom: 38px;
  // height: 1138px;
  img {
    width: 33.333%;
    height: auto;
  }
}
.black {
  height: 80px;
  background: #000;
}
</style>
